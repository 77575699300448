<template>
  <div class="knowledge_wrap">
    <el-header style="height: 60px">
      <home-topbar :is-home="true" :is-personal="true"> </home-topbar>
    </el-header>
    <div class="documentSpace-con">
      <div v-if="!knowledge_edit" class="left-wrap">
        <left-menu
          class="leftMenu"
          ref="leftMenu"
          :menu-list="menuList"
          :checked-node="checkedNode"
          @checked="checked"
          @updateMenu="getDocuments"
          @draggable="draggableFn"
          @isCollapse="isCollapse"
        ></left-menu>
      </div>
      <zdheditor style="flex: 1" :key="$route.fullPath"></zdheditor>
    </div>
  </div>
</template>

<script>
import zdheditor from "@/views/tiptap";
import HomeTopbar from "@/components/homeTopbar";
import leftMenu from "@/views/knowledge/components/leftMenu.vue";
import { getDocuments, getAuth } from "@/network/knowledge";
import { get_allreadyuse_status_list } from "@/network/workflow/index.js";
export default {
  components: {
    HomeTopbar,
    leftMenu,
    zdheditor,
  },
  data() {
    return {
      knowledge_edit: false,
      menuList: [],
      checkedNode: null,
      draggable: true,
      authList: [],
      catalogueData: [],
      rightCommentList: [],
      completeGetSetting: false,
      statusList: [],
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    this.getDocuments();
    this.getStatusList();
  },
  methods: {
    async getStatusList() {
      this.statusList = await get_allreadyuse_status_list(this.get_pid());
    },
    getSetting() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getAuth(params).then((res) => {
        this.authList = res;
        this.completeGetSetting = true;
      });
    },
    checked(node) {
      this.authList = [];
      this.completeGetSetting = false;
      this.checkedNode = node;
      node ? this.getSetting() : "";
    },
    isCollapse(flag) {
      if (flag) {
        document.querySelector(".left-wrap").style.minWidth = "30px";
        document.querySelector(".left-wrap").style.width = "30px";
      } else {
        document.querySelector(".left-wrap").style.minWidth = "300px";
        document.querySelector(".left-wrap").style.width = "300px";
      }
    },
    draggableFn() {
      this.draggable = false;
    },
    getDocuments() {
      const params = {
        projectId: this.get_pid(),
      };
      getDocuments(params).then((res) => {
        this.menuList = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.knowledge_wrap {
  width: 100%;
  height: 100%;
}
.left-wrap {
  width: 300px;
  height: 100%;
}
.leftMenu {
  height: 100%;
  width: 300px;
}
.documentSpace-con {
  display: flex;
  align-items: center;
  height: calc(100% - 60px);
}
</style>
